import brbeshShaffat from "../images/AdwateProducts/brbeshShaffat.png";
import rasshaffat from "../images/AdwateProducts/rasshaffat.png";
import ghates2inch from "../images/AdwateProducts/ghates2inch.png";
import ghates4inch from "../images/AdwateProducts/ghates4inch.png";
import pressuergage from "../images/AdwateProducts/pressuergage.png";
import semiairhand from "../images/AdwateProducts/semiairhand.png";
import longairhand from "../images/AdwateProducts/longairhand.png";
import air2hand from "../images/AdwateProducts/air2hand.png";
import air3hand from "../images/AdwateProducts/air3hand.png";
import air4hand from "../images/AdwateProducts/air4hand.png";
import torte2a from "../images/AdwateProducts/torte2a.png";
import torte2a22 from "../images/AdwateProducts/torte2a22.png";
import torte2a3 from "../images/AdwateProducts/torte2a3.png";
import set5 from "../images/AdwateProducts/set5.png";
import wrench6 from "../images/AdwateProducts/wrench6.png";
import wrench7 from "../images/AdwateProducts/wrench7.png";
import wrench1 from "../images/AdwateProducts/wrench1.png";
import wrench8 from "../images/AdwateProducts/wrench8.png";
import wrench2 from "../images/AdwateProducts/wrench2.png";
import wrench5 from "../images/AdwateProducts/wrench5.png";
import wrench3 from "../images/AdwateProducts/wrench3.png";
import wrench4 from "../images/AdwateProducts/wrench4.png";
import bigDrill from "../images/AdwateProducts/bigDrill.png";
import sprayer2 from "../images/AdwateProducts/sprayer2.png";
import sprayer3 from "../images/AdwateProducts/sprayer3.png";
import oilSprayer from "../images/AdwateProducts/oilSprayer.png";
import oil2Sprayer from "../images/AdwateProducts/oil2Sprayer.png";
import pressureguage2 from "../images/AdwateProducts/pressureguage2.png";
import fltrmaiwZeet from "../images/AdwateProducts/fltrmaiwZeet.png";
import sprayer4 from "../images/AdwateProducts/sprayer4.png";
import oilssprayer from "../images/AdwateProducts/oilssprayer.png";
import frd8reb from "../images/AdwateProducts/frd8reb.png";
import wrench9 from "../images/AdwateProducts/wrench9.png";
import maknt7af2 from "../images/AdwateProducts/maknt7af2.png";
import mfate7set2 from "../images/AdwateProducts/mfate7set2.png";
import mfate7set3 from "../images/AdwateProducts/mfate7set3.png";
import mfate7set1 from "../images/AdwateProducts/mfate7set1.png";
import ringringset from "../images/AdwateProducts/ringringset.png";
import torte2aset2 from "../images/AdwateProducts/torte2aset2.png";
import torte2aset3 from "../images/AdwateProducts/torte2aset3.png";
import torte2aset4 from "../images/AdwateProducts/torte2aset4.png";
import set3 from "../images/AdwateProducts/set3.png";
import set4 from "../images/AdwateProducts/set4.png";
import tajsleesset from "../images/AdwateProducts/tajsleesset.png";
import breakset1 from "../images/AdwateProducts/breakset1.png";
import breakset2 from "../images/AdwateProducts/breakset2.png";
import bresa from "../images/AdwateProducts/bresa.png";

import torks from "../images/AdwateProducts/torks.png";
import set7 from "../images/AdwateProducts/set7.png";
import mfkat1 from "../images/AdwateProducts/mfkat1.png";
import mfkat3 from "../images/AdwateProducts/mfkat3.png";
import batterytester from "../images/AdwateProducts/batterytester.png";
import batterycharger from "../images/AdwateProducts/batterycharger.png";
import custommfk from "../images/AdwateProducts/custommfk.png";
import washerhand from "../images/AdwateProducts/washerhand.png";
import washer2hand from "../images/AdwateProducts/washer2hand.png";
import washer3hand from "../images/AdwateProducts/washer3hand.png";
import brbeshgetoor from "../images/AdwateProducts/brbeshgetoor.png";
import brbeshgetoor2 from "../images/AdwateProducts/brbeshgetoor2.png";
import mshd1 from "../images/AdwateProducts/mshd1.png";
import mshd2 from "../images/AdwateProducts/mshd2.png";
import janzeer from "../images/AdwateProducts/janzeer.png";
import zradyebigbig from "../images/AdwateProducts/zradyebigbig.png";
import smallsaw from "../images/AdwateProducts/smallsaw.png";
import smallsaw2 from "../images/AdwateProducts/smallsaw2.png";
import customwrench from "../images/AdwateProducts/customwrench.png";
import customwrench2 from "../images/AdwateProducts/customwrench2.png";

import weldingmulti from "../images/AdwateProducts/weldingmulti.png";
import welding250 from "../images/AdwateProducts/welding250.png";
import welding270 from "../images/AdwateProducts/welding270.png";
import generator from "../images/AdwateProducts/generator.png";
import oilcollector from "../images/AdwateProducts/oilcollector.png";
import vipwasher from "../images/AdwateProducts/vipwasher.png";
import jacknosTon from "../images/AdwateProducts/jacknosTon.png";
import mover from "../images/AdwateProducts/mover.png";

import pressor from "../images/AdwateProducts/pressor.png";
import pressor2 from "../images/AdwateProducts/pressor2.png";
import ironfoam from "../images/AdwateProducts/ironfoam.png";
import stainlessfoam from "../images/AdwateProducts/stainlessfoam.jpg";
import plasticfoam from "../images/AdwateProducts/plasticfoam.png";

import autotirechanger from "../images/AdwateProducts/autotirechanger.png";
import changer from "../images/AdwateProducts/changer.png";
import balancer from "../images/AdwateProducts/balancer.png";
import chair from "../images/AdwateProducts/chair.png";
import leftjml from "../images/AdwateProducts/leftjml.png";
import chain from "../images/AdwateProducts/chain.png";
// import jacktype from "../images/AdwateProducts/jacktype.png";
import doubleengine from "../images/AdwateProducts/doubleengine.jpg";
import hoist from "../images/AdwateProducts/hoist.png";
import enginhold from "../images/AdwateProducts/enginhold.png";

// import jackstand from "../images/AdwateProducts/jackstand.png";
import jackstand2 from "../images/AdwateProducts/jackstand2.png";
import jacktype2 from "../images/AdwateProducts/jacktype2.png";
import toolcartt from "../images/AdwateProducts/toolcartt.png";
import bed from "../images/AdwateProducts/bed.png";
// import welder from "../images/AdwateProducts/welder.png";
import boxset from "../images/AdwateProducts/boxset.png";
import boxset2 from "../images/AdwateProducts/boxset2.png";
import cabinetempty from "../images/AdwateProducts/cabinetempty.png";
import fullCabinet from "../images/AdwateProducts/fullCabinet.png";
import bigset from "../images/AdwateProducts/bigset.png";

import carpetandsofa from "../images/AdwateProducts/carpetandsofa.png";
import carpetcleaner60 from "../images/AdwateProducts/carpetcleaner60.png";
import vacuumcleaner from "../images/AdwateProducts/vacuumcleaner.png";
import superclean from "../images/AdwateProducts/superclean.png";
import polishtee from "../images/AdwateProducts/polishtee.png";

import a90bar from "../images/AdwateProducts/90bar.png";
import a150bar from "../images/AdwateProducts/150bar.png";

const products = [
  {
    id: "01",
    productName: "بربيش شفاط غبار",
    imgUrl: brbeshShaffat,
    category: "شفاط غبار",
    price: 20,
    shortDesc:
      "بربيش شفاط غبار ذات جودة صناعية عالية وسماكة ممتازة ، 20 شيكل المتر",
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Optio nostrum accusantium iste, voluptas cumque provident! Consequatur officiis animi rem tempore voluptate cumque hic similique aperiam ut consectetur distinctio repudiandae quia quam quos, quas illo, iusto, necessitatibus odio veniam exercitationem quis voluptatibus debitis laboriosam! Esse debitis obcaecati blanditiis at impedit quibusdam!",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    avgRating: 4.5,
    // attributes: [
    //   { name: "name", value: "value" },
    //   { name: "name", value: "value" },
    //   { name: "name", value: "value" },
    // ],
  },
  {
    id: "02",
    productName: "راسية تشفيط",
    imgUrl: rasshaffat,
    category: "شفاط غبار",
    price: 20,
    shortDesc:
      "راسية تشفيط يتم تركيبها على بربيش شفاط الغبار  لتنظيف الأماكن الضيقة وجوانب السيارات وغيرها ذات جودة صناعية عالية",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    avgRating: 4.5,
  },
  {
    id: "03",
    productName: "طرمبة غاطس 1 انش",
    imgUrl: ghates2inch,
    category: "طرمبة",
    price: 650,
    shortDesc:
      "طرمبة ماء غاطس 1 انش  تعمل على ارتفاع لغاية 30م عمودي, ذات جودة صناعية عالية",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    avgRating: 4.5,
  },
  {
    id: "04",
    productName: "طرمبة غاطس 2 انش",
    imgUrl: ghates4inch,
    category: "طرمبة",
    price: 650,
    oldPrice: 750,
    shortDesc:
      "طرمبة ماء غاطس 2 انش تعمل على ارتفاع لغاية11م عمودي, مع فرامة تستخدم للماء الملوثة, ذات جودة صناعية عالية",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    avgRating: 4.5,
  },
  {
    id: "05",
    productName: "فرد تنفيخ إطارات ",
    imgUrl: pressuergage,
    category: "فرد تنفيخ",
    price: 35,
    shortDesc:
      "فرد تنفيخ إطارات مع ساعة قياس ضغط الهواء , تصميم عملي ومريح  ,ذات جودة صناعية عالية",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    avgRating: 4.5,
  },
  {
    id: "06",
    productName: "فرد تنفيخ حديد ",
    imgUrl: air3hand,
    category: "فرد تنفيخ",
    price: 20,
    oldPrice: 35,
    shortDesc: "فرد تنفيخ ذات جودة صناعية عالية",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    avgRating: 4.5,
  },
  {
    id: "07",
    productName: "فرد تنفيخ بلاستيك قصير ",
    imgUrl: semiairhand,
    category: "فرد تنفيخ",
    price: 15,
    oldPrice: 20,
    shortDesc: "فرد تنفيخ ذات جودة صناعية عالية",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    avgRating: 4.5,
  },
  {
    id: "08",
    productName: "فرد تنفيخ بلاستيك طويل ",
    imgUrl: longairhand,
    category: "فرد تنفيخ",
    price: 25,
    shortDesc: "فرد تنفيخ ذات جودة صناعية عالية",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    avgRating: 4.5,
  },
  {
    id: "09",
    productName: "فرد نفخ عجلات",
    imgUrl: air4hand,
    category: "فرد تنفيخ",
    price: 15,
    oldPrice: 30,
    shortDesc: "فرد تنفيخ إطارات ذات جودة صناعية عالية",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    avgRating: 4.5,
  },
  {
    id: "10",
    productName: "فرد نفخ عجلات عادي",
    imgUrl: air2hand,
    category: "فرد تنفيخ",
    price: 10,
    oldPrice: 25,
    shortDesc: "فرد تنفيخ إطارات ذات جودة صناعية عالية",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    avgRating: 4.5,
  },
  {
    id: "11",
    productName: "طرطيقة هواء 1/2 انش",
    imgUrl: torte2a,
    category: "طرطيقة",
    price: 220,
    shortDesc: "طرطيقة هواء 1/2 انش ذات جودة صناعية عالية",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    avgRating: 4.5,
  },
  {
    id: "12",
    productName: "طرطيقة هواء 3/8 انش",
    imgUrl: torte2a,
    category: "طرطيقة",
    price: 220,
    oldPrice: 280,
    shortDesc: "طرطيقة هواء 3/8 انش ذات جودة صناعية عالية",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    avgRating: 4.5,
  },
  {
    id: "13",
    productName: "طرطيقة هواء 1/4 انش",
    imgUrl: torte2a22,
    category: "طرطيقة",
    price: 150,
    shortDesc: "طرطيقة هواء 1/4 انش ذات جودة صناعية عالية",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    avgRating: 4.5,
  },
  {
    id: "14",
    productName: "طرطيقة حجر جلخ",
    imgUrl: torte2a3,
    category: "طرطيقة",
    price: 120,
    shortDesc: "طرطيقة حجر جلح اعوج, ذات جودة صناعية عالية",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    avgRating: 4.5,
  },
  {
    id: "15",
    productName: "طقم حجر جلخ",
    imgUrl: set5,
    category: "طرطيقة",
    price: 100,
    shortDesc: "طرطيقة حجر جلح اعوج, ذات جودة صناعية عالية",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    avgRating: 4.5,
  },
  {
    id: "16",
    productName: "فرد بكسات 3/8 انش",
    imgUrl: wrench6,
    category: "فرد بكسات",
    price: 250,
    oldPrice: 320,
    shortDesc: "فرد بكسات 3/8 انش، ذات جودة صناعية 9800ر.ب.م 310ن.م",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    avgRating: 4.5,
  },
  {
    id: "17",
    productName: "فرد بكسات 1/2 انش",
    imgUrl: wrench7,
    category: "فرد بكسات",
    price: 250,
    shortDesc: "فرد بكسات 1/2 انش، حجم صغير ذات جودة صناعية 8000ر.ب.م 400ن.م",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    avgRating: 4.5,
  },
  {
    id: "18",
    productName: "فرد بكسات 1/2 انش",
    imgUrl: wrench1,
    category: "فرد بكسات",
    price: 300,
    shortDesc: "فرد بكسات 1/2 انش، ذات جودة صناعية 6500ر.ب.م 850ن.م",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    avgRating: 4.5,
  },
  {
    id: "19",
    productName: "فرد بكسات 1/2 انش",
    imgUrl: wrench8,
    category: "فرد بكسات",
    price: 300,
    oldPrice: 370,
    shortDesc: "فرد بكسات 1/2 انش، ذات جودة صناعية 7500ر.ب.م 640ن.م",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    avgRating: 4.5,
  },
  {
    id: "20",
    productName: "فرد بكسات 1/2 انش",
    imgUrl: wrench2,
    category: "فرد بكسات",
    price: 600,
    shortDesc:
      "فرد بكسات 1/2 انش، تايواني حجم صغير ذات جودة صناعية 10000ر.ب.م 597ن.م",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    avgRating: 4.5,
  },
  {
    id: "21",
    productName: "فرد بكسات 1/2 انش",
    imgUrl: wrench5,
    category: "فرد بكسات",
    price: 550,
    shortDesc: "فرد بكسات 1/2 انش، تايواني ذات جودة صناعية 8500ر.ب.م 746ن.م",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    avgRating: 4.5,
  },
  {
    id: "22",
    productName: "فرد بكسات 1/2 انش",
    imgUrl: wrench3,
    category: "فرد بكسات",
    price: 650,
    shortDesc: "فرد بكسات 1/2 انش، تايواني ذات جودة صناعية 9000ر.ب.م 949ن.م",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    avgRating: 4.5,
  },
  {
    id: "23",
    productName: "فرد بكسات 3/4 انش",
    imgUrl: wrench4,
    category: "فرد بكسات",
    price: 900,
    shortDesc: "فرد بكسات 3/4 انش، تايواني ذات جودة صناعية 6300ر.ب.م 1356ن.م",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    avgRating: 4.5,
  },
  {
    id: "23",
    productName: "فرد بكسات 1 انش",
    imgUrl: bigDrill,
    category: "فرد بكسات",
    price: 1950,
    shortDesc: "فرد بكسات 1 انش، تايواني ذات جودة صناعية 4300ر.ب.م 3390ن.م",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    avgRating: 4.5,
  },
  {
    id: "24",
    productName: "فرد بوية مقلوب ",
    imgUrl: sprayer2,
    category: "بوية/دهان",
    price: 60,
    shortDesc: "فرد بوية مقلوب ذات جودة صناعية عالية, مخرج الديزة 1.5مم",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    avgRating: 4.5,
  },
  {
    id: "25",
    productName: "فرد بوية",
    imgUrl: sprayer3,
    category: "بوية/دهان",
    price: 60,
    oldPrice: 100,
    shortDesc: "فرد بوية ذات جودة صناعية عالية, مخرج الديزة 1.8مم",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    avgRating: 4.5,
  },
  {
    id: "26",
    productName: "فرد سولار بلاستيك",
    imgUrl: oilSprayer,
    category: "فرد سولار",
    price: 40,
    shortDesc: "فرد سولار بلاستيك , ذات جودة صناعية عالية",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    avgRating: 4.5,
  },
  {
    id: "27",
    productName: "فرد سولار حديد",
    imgUrl: oil2Sprayer,
    category: "فرد سولار",
    price: 35,
    shortDesc: "فرد سولار حديد , ذات جودة صناعية عالية",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    avgRating: 4.5,
  },
  {
    id: "28",
    productName: "فلتر ماء",
    imgUrl: pressureguage2,
    category: "فلتر",
    price: 70,
    oldPrice: 85,
    shortDesc: "فلتر ماء ذات جودة صناعية عالية",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    avgRating: 4.5,
  },
  {
    id: "29",
    productName: "فلتر ماء وزيت",
    imgUrl: fltrmaiwZeet,
    category: "فلتر",
    price: 120,
    shortDesc: "فلتر ماء وزيت ذات جودة صناعية عالية",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    avgRating: 4.5,
  },
  {
    id: "30",
    productName: "فرد ماكنة صابونة",
    imgUrl: sprayer4,
    category: "ماكنة صابونة",
    price: 50,
    shortDesc: "فرد ماكنة صابونة ذات جودة صناعية عالية",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    avgRating: 4.5,
  },
  {
    id: "31",
    productName: "مزيتة حديد",
    imgUrl: oilssprayer,
    category: "مزيتة",
    price: 20,
    oldPrice: 40,
    shortDesc: "مزيتة حديد ذات جودة صناعية عالية",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    avgRating: 4.5,
  },
  {
    id: "32",
    productName: "فرد تباشيم هواء",
    imgUrl: frd8reb,
    category: "فرد",
    price: 250,
    shortDesc: "فرد تباشيم هوائي سرعة وكفاءة عالية,  ذات جودة صناعية عالية",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    avgRating: 4.5,
  },
  {
    id: "33",
    productName: "مقدح 3/8 انش مع أزميل",
    imgUrl: wrench9,
    category: "مقدح",
    price: 100,
    shortDesc: "مقدح  3/8 انش مع ازميل, ذات جودة صناعية عالية",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    avgRating: 4.5,
  },
  {
    id: "34",
    productName: "ماكنة حف",
    imgUrl: maknt7af2,
    category: "ماكنة حف",
    price: 200,
    shortDesc: "ماكنة حف ذات جودة صناعية عالية",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    avgRating: 4.5,
  },
  {
    id: "35",
    productName: "طقم شق رينج 12 قطعة",
    imgUrl: mfate7set3,
    category: "طقم رينج",
    price: 100,
    shortDesc: "طقم شق رينج 12 قطعة من (6-32) مم,  ذات جودة صناعية عالية",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    avgRating: 4.5,
  },
  {
    id: "36",
    productName: "طقم شق رينج 14 قطعة",
    imgUrl: mfate7set2,
    category: "طقم رينج",
    price: 120,
    oldPrice: 160,
    shortDesc: "طقم شق رينج 14 قطعة من (8-24) مم,  ذات جودة صناعية عالية",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    avgRating: 4.5,
  },
  {
    id: "37",
    productName: "طقم شق رينج 20 قطعة",
    imgUrl: mfate7set1,
    category: "طقم رينج",
    price: 220,
    shortDesc: "طقم شق رينج 20 قطعة من (6-32) مم,  ذات جودة صناعية عالية",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    avgRating: 4.5,
  },
  {
    id: "38",
    productName: "طقم رينج رينج 20 قطعة",
    imgUrl: ringringset,
    category: "طقم رينج",
    price: 140,
    shortDesc: "طقم رينج رينج 12 قطعة من (6-32) مم,  ذات جودة صناعية عالية",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    avgRating: 4.5,
  },
  {
    id: "39",
    productName: "طقم بكسات 37 قطعة",
    imgUrl: torte2aset2,
    category: "طقم بكسات",
    price: 180,
    oldPrice: 250,
    shortDesc:
      "طقم بكسات  37 قطعة من الشركة العالمية يوفي,  ذات جودة صناعية عالية",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    avgRating: 4.5,
  },
  {
    id: "40",
    productName: "طقم بكسات 121 قطعة",
    imgUrl: torte2aset3,
    category: "طقم بكسات",
    price: 400,
    shortDesc:
      "طقم بكسات  121 قطعة من الشركة العالمية يوفي,  ذات جودة صناعية عالية",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    avgRating: 4.5,
  },
  {
    id: "41",
    productName: "طقم بكسات 151 قطعة",
    imgUrl: torte2aset4,
    category: "طقم بكسات",
    price: 600,
    shortDesc:
      "طقم بكسات  151 قطعة من الشركة العالمية يوفي,  ذات جودة صناعية عالية",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    avgRating: 4.5,
  },
  {
    id: "42",
    productName: "طقم بكسات 38 قطعة",
    imgUrl: set3,
    category: "طقم بكسات",
    price: 60,
    oldPrice: 90,
    shortDesc: "طقم بكسات 1/4 انش 38 قطعة,  ذات جودة صناعية عالية",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    avgRating: 4.5,
  },
  {
    id: "43",
    productName: "طقم بكسات 46 قطعة",
    imgUrl: set4,
    category: "طقم بكسات",
    price: 70,
    shortDesc: "طقم بكسات 1/4 انش 46 قطعة,  ذات جودة صناعية عالية",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    avgRating: 4.5,
  },
  {
    id: "44",
    productName: "طقم تجليس",
    imgUrl: tajsleesset,
    category: "طقم صيانة",
    price: 200,
    shortDesc: "طقم تجليس 6 قطع مع رجل,ذات جودة صناعية عالية",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    avgRating: 4.5,
  },
  {
    id: "45",
    productName: "بريصة بريك مفرد",
    imgUrl: breakset1,
    category: "طقم صيانة",
    price: 120,
    oldPrice: 170,
    shortDesc: "بريصة بريك مفرد 12 قطعة ,ذات جودة صناعية عالية",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    avgRating: 4.5,
  },
  {
    id: "46",
    productName: "بريصة بريك مجوز",
    imgUrl: breakset2,
    category: "طقم صيانة",
    price: 200,
    shortDesc: "بريصة بريك مجوز 18 قطعة ,ذات جودة صناعية عالية",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    avgRating: 4.5,
  },
  {
    id: "47",
    productName: "بريصة بريك هواء",
    imgUrl: bresa,
    category: "طقم صيانة",
    price: 320,
    shortDesc: "بريصة بريك هواء 16 قطعة ,ذات جودة صناعية عالية",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    avgRating: 4.5,
  },
  {
    id: "48",
    productName: "طقم توركس 40 قطعة",
    imgUrl: torks,
    category: "طقم صيانة",
    price: 70,
    shortDesc: "طقم توركس 40 قطعةذات جودة صناعية عالية",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    avgRating: 4.5,
  },
  {
    id: "49",
    productName: "طقم مفكات",
    imgUrl: set7,
    category: "طقم صيانة",
    price: 60,
    shortDesc: "طقم مفكات ذات جودة صناعية عالية",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    avgRating: 4.5,
  },
  {
    id: "50",
    productName: "طقم مفكات",
    imgUrl: mfkat1,
    category: "طقم صيانة",
    price: 35,
    shortDesc: "طقم مفكات ذات جودة صناعية عالية",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    avgRating: 4.5,
  },
  {
    id: "51",
    productName: "طقم مفكات",
    imgUrl: mfkat3,
    category: "طقم صيانة",
    price: 20,
    oldPrice: 35,
    shortDesc: "طقم مفكات ذات جودة صناعية عالية",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    avgRating: 4.5,
  },
  {
    id: "52",
    productName: "فاحص بطارية",
    imgUrl: batterytester,
    category: "بطارية",
    price: 130,
    shortDesc: "فاحص بطارية ذات جودة صناعية عالية",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    avgRating: 4.5,
  },
  {
    id: "53",
    productName: "شاحن 30 أمبير بطارية",
    imgUrl: batterycharger,
    category: "بطارية",
    price: 320,
    shortDesc: "تشارجر (شاحن) 30 امبير ,12 و24 فولت , ذات جودة صناعية عالية",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    avgRating: 4.5,
  },
  {
    id: "54",
    productName: "مغناطيس",
    imgUrl: custommfk,
    category: "مغناطيس",
    price: 15,
    shortDesc: "مغناطيس ذات جودة صناعية عالية",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    avgRating: 4.5,
  },
  {
    id: "55",
    productName: "فرد جيتور",
    imgUrl: washer3hand,
    category: "جيتور ماء",
    price: 70,
    oldPrice: 110,
    shortDesc: "فرد جيتور ذات جودة صناعية عالية",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    avgRating: 4.5,
  },
  {
    id: "110",
    productName: "جيتور ماء 90 بار",
    imgUrl: a90bar,
    category: "جيتور ماء",
    price: 400,
    oldPrice: 500,
    shortDesc:
      "جيتور ماء منزلي بقوة قصوى 13 ميجا بكسل و 1500 واط، مع كامل القطع و فرد و بربيش",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    avgRating: 4.5,
  },
  {
    id: "111",
    productName: "جيتور ماء صناعي 150 بار",
    imgUrl: a150bar,
    category: "جيتور ماء",
    price: 2000,
    oldPrice: 2500,
    shortDesc: "جيتور ماء صناعي بقوة 150 بار مع كامل القطع و فرد و بربيش",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    avgRating: 4.5,
  },
  {
    id: "56",
    productName: "فرد جيتور",
    imgUrl: washer2hand,
    category: "جيتور ماء",
    price: 120,
    shortDesc: "فرد جيتور ذات جودة صناعية عالية",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    avgRating: 4.5,
  },
  {
    id: "57",
    productName: "فرد جيتور 70سم",
    imgUrl: washerhand,
    category: "جيتور ماء",
    price: 140,
    shortDesc: "فرد جيتور بطول 70 سم ذات جودة صناعية عالية",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    avgRating: 4.5,
  },
  {
    id: "58",
    productName: "فرد جيتور 90سم",
    imgUrl: washerhand,
    category: "جيتور ماء",
    price: 170,
    shortDesc: "فرد جيتور بطول 90 سم ذات جودة صناعية عالية",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    avgRating: 4.5,
  },
  {
    id: "59",
    productName: "بربيش جيتور 6 متر",
    imgUrl: brbeshgetoor,
    category: "جيتور ماء",
    price: 50,
    oldPrice: 60,
    shortDesc: "بربيش جيتور بطول 6متر, ذات جودة صناعية عالية",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    avgRating: 4.5,
  },
  {
    id: "60",
    productName: "بربيش جيتور 10 متر",
    imgUrl: brbeshgetoor2,
    category: "جيتور ماء",
    price: 70,
    shortDesc: "بربيش جيتور بطول 10 متر, ذات جودة صناعية عالية",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    avgRating: 4.5,
  },
  {
    id: "61",
    productName: "مفتاح فلتر أزرق",
    imgUrl: mshd1,
    category: "فلتر",
    price: 25,
    oldPrice: 35,
    shortDesc: "مفتاح فلتر  قشط مع  يد حديد",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    avgRating: 4.5,
  },
  {
    id: "62",
    productName: "مفتاح فلتر أحمر",
    imgUrl: mshd2,
    category: "فلتر",
    price: 40,
    shortDesc: "مفتاح فلتر  قشط مع  يد حديد",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    avgRating: 4.5,
  },
  {
    id: "63",
    productName: "مفتاح فلتر أخضر",
    imgUrl: janzeer,
    category: "فلتر",
    price: 30,
    shortDesc: "مفتاح فلتر  جنزير مع  يد حديد",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    avgRating: 4.5,
  },
  // {
  //   id: "64",
  //   productName: "مفتاح فلتر أعوج",
  //   imgUrl: janzeer,
  //   category: "فلتر",
  //   price: 30,
  //   shortDesc: "مفتاح فلتر  3 أرجل أعوج",
  //   reviews: [
  //     {
  //       rating: 4.7,
  //       text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
  //     },
  //   ],
  //   avgRating: 4.5,
  // },
  // {
  //   id: "65",
  //   productName: "مفتاح فلتر مبسط",
  //   imgUrl: janzeer,
  //   category: "فلتر",
  //   price: 30,
  //   shortDesc: "مفتاح فلتر  3 أرجل مبسطة",
  //   reviews: [
  //     {
  //       rating: 4.7,
  //       text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
  //     },
  //   ],
  //   avgRating: 4.5,
  // },
  // {
  //   id: "66",
  //   productName: "طقم زرادية كبشايات",
  //   imgUrl: torte2aset4,
  //   category: "طقم صيانة",
  //   price: 60,
  //   shortDesc: "طقم زرادية كبشايات 4 قطع بحجم 7 انش , ذات جودة صناعية عالية",
  //   reviews: [
  //     {
  //       rating: 4.7,
  //       text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
  //     },
  //   ],
  //   avgRating: 4.5,
  // },
  {
    id: "67",
    productName: "مقص حديد 24 انش",
    imgUrl: zradyebigbig,
    category: "مقص",
    price: 100,
    shortDesc: "مقص حديد24 انش,ذات جودة صناعية عالية",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    avgRating: 4.5,
  },
  {
    id: "68",
    productName: "جلخ هواء 3 انش",
    imgUrl: smallsaw,
    category: "جلخ",
    price: 150,
    shortDesc: "جلخ هواء 3 انش ذات جودة صناعية عالية",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    avgRating: 4.5,
  },
  {
    id: "69",
    productName: "جلخ هواء 4 انش",
    imgUrl: smallsaw2,
    category: "جلخ",
    price: 180,
    shortDesc: "جلخ هواء 4 انش ذات جودة صناعية عالية",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    avgRating: 4.5,
  },
  {
    id: "70",
    productName: "مفتاح سويدي 24 انش",
    imgUrl: customwrench,
    category: "طقم رينج",
    price: 100,
    shortDesc: "مفتاح سويدي 24 انش ذات جودة صناعية عالية",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    avgRating: 4.5,
  },
  {
    id: "71",
    productName: "مفتاح سويدي 18 انش",
    imgUrl: customwrench2,
    category: "طقم رينج",
    price: 50,
    oldPrice: 65,
    shortDesc: "مفتاح سويدي 18 انش ذات جودة صناعية عالية",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    avgRating: 4.5,
  },
  {
    id: "72",
    productName: "نتاشة بودي 4500",
    imgUrl: weldingmulti,
    category: "ماكنة صناعية",
    price: 3300,
    oldPrice: 3500,
    shortDesc:
      "نتاشة بودي بقدرة 4500 ,1فاز ,220 فولت.جودة صناعية عالية جدا وكفالة حقيقية",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    avgRating: 4.5,
  },
  {
    id: "73",
    productName: "CO2 ماكنة لحام 250 أمبير",
    imgUrl: welding250,
    category: "ماكنة صناعية",
    price: 3500,
    oldPrice: 3700,
    shortDesc: "ف 220 فولت ذات جودة صناعية عاليةCO2 ماكنة لحام 250 أمبير",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    avgRating: 4.5,
  },
  {
    id: "74",
    productName: "CO2 ماكنة لحام 270 أمبير",
    imgUrl: welding270,
    category: "ماكنة صناعية",
    price: 3800,
    shortDesc: "ف 380 فولت 3 فاز ذات جودة صناعية عاليةCO2 ماكنة لحام 270 أمبير",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    avgRating: 4.5,
  },
  {
    id: "75",
    productName: "مولد 3كيلو بنزين",
    imgUrl: generator,
    category: "مولد كهرباء",
    price: 1200,
    oldPrice: 1500,
    shortDesc:
      "ATS مولد 3 كيلو بنزين ,3500 ك.ف.ا ,ملف نحاس بشكل كامل بالاضافة لنظام التحويل التلقائي. جودة صناعية عالية وكفالة حقيقية",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    avgRating: 4.5,
  },
  {
    id: "76",
    productName: "شفاط زيت",
    imgUrl: oilcollector,
    category: "ماكنة صناعية",
    price: 1500,
    shortDesc: "شفاط زيت (زجاجة وصحن), جودة صناعية عالية وكفالة حقيقية",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    avgRating: 4.5,
  },
  {
    id: "77",
    productName: "جيتور 140 بار",
    imgUrl: vipwasher,
    category: "جيتور ماء",
    price: 1200,
    oldPrice: 1300,
    shortDesc:
      "جيتور 140 بار صناعي,ماتور نحاس بالكامل , مزود بشفاط صابون , يتحمل ساعات عمل طويلة , جودة عالية وكفالة حقيقية",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    avgRating: 4.5,
  },
  {
    id: "78",
    productName: "جك جمل 0.5 طن",
    imgUrl: jacknosTon,
    category: "جك",
    price: 1900,
    shortDesc:
      "(MAZZOLA) جك جمل 0.5 طن من الشركة الايطالية جودة صناعية عالية وكفالة حقيقية",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    avgRating: 4.5,
  },
  {
    id: "79",
    productName: "عرباية مشتاح",
    imgUrl: mover,
    category: "عرباية",
    price: 1150,
    oldPrice: 1300,
    shortDesc: " عرباية مشتاح 2.5 طن ذات جودة صناعية عالية",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    avgRating: 4.5,
  },
  {
    id: "80",
    productName: "مكبس 20 طن",
    imgUrl: pressor,
    category: "مكبس",
    price: 3300,
    shortDesc: "(MAZZOLA) مكبس ايطيالي 20 طن ذات جودة صناعية عالية",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    avgRating: 4.5,
  },
  {
    id: "81",
    productName: "مكبس 30 طن",
    imgUrl: pressor2,
    category: "مكبس",
    price: 2500,
    oldPrice: 2800,
    shortDesc: "(MAZZOLA) مكبس ايطيالي 30 طن ذات جودة صناعية عالية",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    avgRating: 4.5,
  },
  {
    id: "82",
    productName: "ماكنة صابون حديد",
    imgUrl: ironfoam,
    category: "ماكنة صابونة",
    price: 400,
    oldPrice: 500,
    shortDesc: "ماكنة صابونة حديد ذات جودة صناعية عالية, بسعة 70 لتر",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    avgRating: 4.5,
  },
  {
    id: "83",
    productName: "ماكنة صابون ستانلي",
    imgUrl: stainlessfoam,
    category: "ماكنة صابونة",
    price: 600,
    shortDesc: "ماكنة صابونة ستانلي ذات جودة صناعية عالية, بسعة 70 لتر",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    avgRating: 4.5,
  },
  {
    id: "84",
    productName: "ماكنة صابون بلاستيك",
    imgUrl: plasticfoam,
    category: "ماكنة صابونة",
    price: 650,
    shortDesc: "ماكنة صابونة بلاستيك ذات جودة صناعية عالية, بسعة 70 لتر",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    avgRating: 4.5,
  },
  {
    id: "85",
    productName: "ماكنة بناشر 24 انش",
    imgUrl: autotirechanger,
    category: "ماكنة صناعية",
    price: 9500,
    shortDesc: "ماكنة بناشرفل اتوماتيك بمقاس 24 انش , تحتاج هواء  من 8 -10 بار",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    avgRating: 4.5,
  },
  {
    id: "86",
    productName: "ماكنة بناشر 22 انش",
    imgUrl: changer,
    category: "ماكنة صناعية",
    price: 5900,
    oldPrice: 6500,
    shortDesc: "ماكنة بناشرفل اتوماتيك بمقاس 22 انش , تحتاج هواء  من 8 -10 بار",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    avgRating: 4.5,
  },
  {
    id: "87",
    productName: "ماكنة بلنص عجلات",
    imgUrl: balancer,
    category: "ماكنة صناعية",
    price: 5500,
    oldPrice: 6100,
    shortDesc:
      "ماكنة بلنص(ترصيص) العجلات  تحتوي على شاشة , بمقاس 24 انش , 1 فاز, 220 فولت ,ماكنة ذات جودة صناعية عالية",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    avgRating: 4.5,
  },
  {
    id: "88",
    productName: "كرسي مكانيكي",
    imgUrl: chair,
    category: "عدة",
    price: 200,
    shortDesc:
      "كرسي ميكانيكي  قابل لرفع والتنزيل  ,يمتاز بالجودة العالية  والقدرة على التحمل",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    avgRating: 4.5,
  },
  {
    id: "89",
    productName: "جك جمل 2 طن",
    imgUrl: leftjml,
    category: "جك",
    price: 950,
    oldPrice: 1200,
    shortDesc:
      "كرسي ميكانيكي  قابل لرفع والتنزيل  ,يمتاز بالجودة العالية  والقدرة على التحمل",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    avgRating: 4.5,
  },
  {
    id: "90",
    productName: "بلانكو كهرباء 1 طن",
    imgUrl: chain,
    category: "ماكنة صناعية",
    price: 3500,
    shortDesc:
      "بلانكو كهرباء صناعي 1طن ,1فاز,جنزير بطول 11متر يمتاز بجودة صناعية عالية وكفالة حقيقية",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    avgRating: 4.5,
  },
  // {
  //   id: "91",
  //   productName: "جك زيت وهواء 20 طن",
  //   imgUrl: jacktype,
  //   category: "ماكنة صناعية",
  //   price: 0,
  //   shortDesc: "جك زيت وهواء 20 طن,يمتاز بجودة صناعية عالية وكفالة حقيقية",
  //   reviews: [
  //     {
  //       rating: 4.7,
  //       text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
  //     },
  //   ],
  //   avgRating: 4.5,
  // },
  {
    id: "92",
    productName: "حامل محرك 300 كيلو",
    imgUrl: doubleengine,
    category: "ماكنة صناعية",
    price: 400,
    shortDesc: "حامل ماتور 300 كيلو , جودة صناعية عالية وكفالة حقيقية",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    avgRating: 4.5,
  },
  {
    id: "93",
    productName: "ونش كهرباء",
    imgUrl: hoist,
    category: "ماكنة صناعية",
    price: 450,
    oldPrice: 600,
    shortDesc:
      "ونش كهرباء يحمل من 100-200 كيلو -طول السلك 12 متر , جودة صناعية عالية",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    avgRating: 4.5,
  },
  {
    id: "94",
    productName: "حامل محرك 1000 كيلو",
    imgUrl: enginhold,
    category: "ماكنة صناعية",
    price: 450,
    shortDesc:
      "حامل محرك 1000 كيلو ,يمتاز بهيكل  قوي وقابل للطي, وملزمة دوارة قابلة للضبط لتناسب الاشكال المختلفة من المحركات",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    avgRating: 4.5,
  },
  {
    id: "95",
    productName: "جك ركيزة",
    imgUrl: jackstand2,
    category: "جك",
    price: 130,
    oldPrice: 170,
    shortDesc: "طقم جك ستاند صناعي 3طن , جودة صناعية عالية ",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    avgRating: 4.5,
  },
  {
    id: "96",
    productName: "جك جير",
    imgUrl: jacktype2,
    category: "جك",
    price: 750,
    shortDesc:
      "جك جير مع ملزمة 500كغم  , ملزمة  قابلة للضبط مناسبة لجميع أشكال وأحجام الجير,جودة صناعية عالية وكفالة حقيقية ",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    avgRating: 4.5,
  },
  {
    id: "97",
    productName: "عرباية 3 رفوف",
    imgUrl: toolcartt,
    category: "عرباية عدة",
    price: 200,
    shortDesc:
      "عرباية 3 رفوف تحتوي على عجلات  حرة الحركة  لنقل سلس وسريع تمتاز بالجودة العالية والقدرة على التحمل ",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    avgRating: 4.5,
  },
  {
    id: "98",
    productName: "فرشة ميكانيكي",
    imgUrl: bed,
    category: "عدة",
    price: 120,
    shortDesc:
      "فرشة ميكانيكي تحتوي على عجلات حرة الحركة لنقل سلس وسريع تمتاز بالجودة العالية والقدرة على التحمل ",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    avgRating: 4.5,
  },
  // {
  //   id: "99",
  //   productName: "جك ركيزة",
  //   imgUrl: welder,
  //   category: "جك",
  //   price: 100,
  //   oldPrice: 150,
  //   shortDesc: "طقم جك ستاند صناعي 3طن , جودة صناعية عالية ",
  //   reviews: [
  //     {
  //       rating: 4.7,
  //       text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
  //     },
  //   ],
  //   avgRating: 4.5,
  // },
  {
    id: "100",
    productName: "صندوق عدة 62 قطعة",
    imgUrl: boxset,
    category: "عدة",
    price: 600,
    shortDesc:
      "صندوق عدة من الشركة العالمية FIXMAN  يحتوي على 62 قطعة من العدد اليدوية  ذات الجودة العالية والكفائة الحقيقية ",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    avgRating: 4.5,
  },
  {
    id: "101",
    productName: "صندوق عدة 45 قطعة",
    imgUrl: boxset2,
    category: "عدة",
    price: 550,
    oldPrice: 580,
    shortDesc:
      "صندوق عدة من الشركة العالمية FIXMAN  يحتوي على 45 قطعة من العدد اليدوية  ذات الجودة العالية والكفائة الحقيقية ",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    avgRating: 4.5,
  },
  {
    id: "102",
    productName: "عرباية عدة (فارغة)",
    imgUrl: cabinetempty,
    category: "عرباية عدة",
    price: 1350,
    oldPrice: 1500,
    shortDesc:
      "عرباية عدة (فارغة ) من الشركة العالمية FIXMAN ذات الجودة الصناعية العالية والقدرة على تحمل  ",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    avgRating: 4.5,
  },
  {
    id: "103",
    productName: "عرباية عدة (ممتلئة)",
    imgUrl: fullCabinet,
    category: "عرباية عدة",
    price: 3100,
    shortDesc:
      "عرباية عدة (ممتلئة ) من الشركة العالمية FIXMAN ذات الجودة الصناعية العالية والقدرة على تحمل  ",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    avgRating: 4.5,
  },
  {
    id: "104",
    productName: "مطبخ ميكانيكي",
    imgUrl: bigset,
    category: "عرباية عدة",
    price: 6000,
    shortDesc:
      "مطبخ عدة من الشركة العالمية FIXMAN يحتوي على طاولة عدة والعديد من الخزائن التي تحتوي على رفوف وستاند لتعليق العديد من العدد اليدوية ,يمتاز المطبخ بالجودة الصناعية العالية ",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    avgRating: 4.5,
  },
  {
    id: "105",
    productName: "ماكنة تنظيف 80 لتر",
    imgUrl: carpetandsofa,
    category: "ماكنة تنظيف",
    price: 2500,
    shortDesc:
      "ماكنة تنظيف السجاد  والكنب والستائر بحجم 80 لتر, ماكنة ذات جودة صناعية عالية وقدرة عالية على التنظيف ,تحتوي على العديد من القطع للتنظيف ",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    avgRating: 4.5,
  },
  {
    id: "106",
    productName: "ماكنة تنظيف 60 لتر",
    imgUrl: carpetcleaner60,
    category: "ماكنة تنظيف",
    price: 2200,
    oldPrice: 2400,
    shortDesc:
      "ماكنة تنظيف السجاد والكنب والستائر بحجم 60 لتر, ماكنة ذات جودة صناعية عالية وقدرة عالية على التنظيف ,تحتوي على العديد من القطع للتنظيف ",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    avgRating: 4.5,
  },
  {
    id: "107",
    productName: "شفاط غبار 80 لتر",
    imgUrl: vacuumcleaner,
    category: "شفاط غبار",
    price: 900,
    shortDesc:
      "شفاط غبار ذات جودة صناعية عالية يعمل بقدرة 3000 واط يحتوي على 3 رؤوس بحجم 80لتر ,يحتوي على العديد من القطع للتنظيف ",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    avgRating: 4.5,
  },
  {
    id: "108",
    productName: "ماكنة تنظيف 30 لتر",
    imgUrl: superclean,
    category: "ماكنة تنظيف",
    price: 2500,
    shortDesc:
      "ماكنة تنظيف السجاد والكنب والستائر مع بخار بحجم 30 لتر, ماكنة ذات جودة صناعية عالية وقدرة عالية على التنظيف ,تحتوي على العديد من القطع للتنظيف ",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    avgRating: 4.5,
  },
  {
    id: "109",
    productName: "ماكنة بولش ارضيات",
    imgUrl: polishtee,
    category: "ماكنة تنظيف",
    price: 2000,
    oldPrice: 2300,
    shortDesc:
      "ماكنة بولش لتنظيف الارضيات والسجاد والعديد من الامور تحتوي الماكنة على لبادة  وفرشاية ناعمة وفرشاية خشنة يتم استخدامهم حسب طبيعة العمل  ,ماكنة ذات جودة صناعية عالية ",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    avgRating: 4.5,
  },
];

export default products;
